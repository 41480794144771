import React from 'react';
import * as y from 'yup';
import { FormikProps } from 'formik';
import { InfoActivity, InfoActivityInput, ActivityTypes } from 'app/api/generated';
import Upload from 'common/components/Upload';
import Select from 'common/components/Select';
import InputText from 'common/components/InputText';
import { MAX_ACTIVITY_NAME_LEN } from 'features/program/validations';
import css from 'features/program/Program.module.css';

const imagePlacementOptions = [
  { value: 'default', label: 'Default' },
  { value: 'cover', label: 'Fill' },
  { value: 'contain', label: 'Fit' },
];
const imagePlacementDefaultOption = imagePlacementOptions[0].value;

const Info: React.FC<{ form: FormikProps<Partial<InfoActivity>> }> = ({ form }) => {
  return (
    <div style={{ width: 530, maxWidth: '100%' }}>
      <InputText
        {...form.getFieldProps('name')}
        touched={form.touched.name}
        error={form.errors.name}
        label="Activity name"
        maxLength={MAX_ACTIVITY_NAME_LEN}
        showCount
        placeholder="Type your Activity name"
        autoComplete="off"
        className={css.field}
      />
      <Upload
        {...form.getFieldProps('mediaUrl')}
        touched={form.touched.mediaUrl}
        error={form.errors.mediaUrl}
        accept="image/png, image/jpeg, video/mp4"
        defaultPlaceholder="Image"
        limit={300}
        placeholder={<p>JPG, PNG, MP4 | 300KB</p>}
        label="Info media"
        onFileChange={form.setFieldValue}
        className={css.field}
      />
      <Select
        {...form.getFieldProps('mediaPlacement')}
        defaultActiveFirstOption
        label="Image placement"
        options={imagePlacementOptions}
        touched={form.touched.mediaPlacement}
        error={form.errors.mediaPlacement}
        onChange={form.setFieldValue}
        className={css.field}
        defaultValue={imagePlacementDefaultOption}
      />
      <InputText
        {...form.getFieldProps('title')}
        touched={form.touched.title}
        error={form.errors.title}
        label="Title"
        placeholder="כתבו כותרת כאן"
        autoComplete="off"
        className={css.field}
      />
      <InputText
        {...form.getFieldProps('subheading')}
        touched={form.touched.subheading}
        error={form.errors.subheading}
        label="Subheading"
        placeholder="כתבו כותרת משנה כאן"
        autoComplete="off"
        className={css.field}
      />
      <InputText
        {...form.getFieldProps('description')}
        touched={form.touched.description}
        error={form.errors.description}
        label="Description"
        labelHint="Linked text should be wrapped in { } brackets"
        placeholder="כתבו טקסט משנה כאן { לינק }"
        autoComplete="off"
        className={css.field}
      />
      <InputText
        {...form.getFieldProps('descriptionLink')}
        touched={form.touched.descriptionLink}
        error={form.errors.descriptionLink}
        label="Description link"
        placeholder="https://..."
        autoComplete="off"
        className={css.field}
      />
      <Upload
        {...form.getFieldProps('animationUrl')}
        touched={form.touched.animationUrl}
        error={form.errors.animationUrl}
        accept="image/png, image/jpeg, image/gif"
        defaultPlaceholder="Image"
        limit={300}
        placeholder={<p>GIF, JPG, PNG | 300KB</p>}
        label="Animation media"
        onFileChange={form.setFieldValue}
        className={css.field}
      />
    </div>
  );
};

export const ValidationSchema: y.SchemaOf<InfoActivityInput> = y.object().shape({
  animationUrl: y.string().optional().nullable(),
  mediaUrl: y.string().optional().nullable(),
  name: y.string().required(),
  title: y.string().test('title', 'title is a required field', function (value) {
    return !!value || !!this.parent.subheading || !!this.parent.description;
  }),
  subheading: y.string().optional().nullable(),
  description: y.string().optional().nullable(),
  mediaPlacement: y.string().optional().nullable().default(imagePlacementDefaultOption),
  descriptionLink: y.string().optional().nullable(),
});

export const initialValues: Partial<InfoActivity> = {
  type: ActivityTypes.Info,
};

export default Info;
