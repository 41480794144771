export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const isProd = true; // window.location.origin === 'https://dashboard.keshet12vote.co.il';

export const domain = {
  dashboard: isProd ? 'https://dashboard.keshet12vote.co.il' : 'https://dashboard.dev.keshet12vote.co.il',
  assets: isProd ? 'https://assets.keshet12vote.co.il' : 'https://assets.dev.keshet12vote.co.il',
  live: isProd ? 'https://keshet12vote.mako.co.il' : 'https://live.dev.keshet12vote.co.il',
};

export const getProgramLiveUrl = (slug: string) => `${domain.live}/?id=${slug}`;

export const getProgramXmlUrl = (programId: string) => `${domain.live}/api/__program__/${programId}/eG1s`;

export const isHebrew = (str = '') => /[\u0590-\u05FF]/.test(str);

export const prependUrlProtocol = ({ url = '', protocol = 'https' }: { url: string; protocol?: string }) => {
  let _url = url.startsWith('//') ? url.replace('//', '') : url;

  if (!/^https?:\/\//i.test(_url)) {
    _url = `${protocol}://${_url}`;
  }

  return _url;
};
